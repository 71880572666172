/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zUtBhPNqw73oHt5D4hTxM.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zUtBhPNqw73oHt7j4hTxM.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zUtBhPNqw73oHt4D4h.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73ord4iYl.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oDd4iYl.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73ord4iYl.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
body {
  font-family: Jost;
}
.display-1 {
  font-family: 'Jost', sans-serif;
  font-size: 4.6rem;
  line-height: 1.1;
}
.display-1 > .mbr-iconfont {
  font-size: 5.75rem;
}
.display-2 {
  font-family: 'Jost', sans-serif;
  font-size: 3rem;
  line-height: 1.1;
}
.display-2 > .mbr-iconfont {
  font-size: 3.75rem;
}
.display-4 {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
}
.display-4 > .mbr-iconfont {
  font-size: 1.375rem;
}
.display-5 {
  font-family: 'Jost', sans-serif;
  font-size: 2rem;
  line-height: 1.5;
}
.display-5 > .mbr-iconfont {
  font-size: 2.5rem;
}
.display-7 {
  font-family: 'Jost', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
}
.display-7 > .mbr-iconfont {
  font-size: 1.5rem;
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 992px) {
  .display-1 {
    font-size: 3.68rem;
  }
}
@media (max-width: 768px) {
  .display-1 {
    font-size: 3.22rem;
    font-size: calc( 2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.1 * (2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-2 {
    font-size: 2.4rem;
    font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.3 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-4 {
    font-size: 0.88rem;
    font-size: calc( 1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-5 {
    font-size: 1.6rem;
    font-size: calc( 1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-7 {
    font-size: 0.96rem;
    font-size: calc( 1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20))));
  }
}
/* Buttons */
.btn {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-sm {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-md {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-lg {
  padding: 1rem 2.6rem;
  border-radius: 4px;
}

.text-primary {
  color: #6592e6 !important;
}
.text-secondary {
  color: #ff6666 !important;
}
.text-success {
  color: #40b0bf !important;
}
.text-info {
  color: #47b5ed !important;
}
.text-warning {
  color: #ffe161 !important;
}
.text-danger {
  color: #ff9966 !important;
}
.text-white {
  color: #fafafa !important;
}
.text-black {
  color: #232323 !important;
}
a.text-primary:hover,
a.text-primary:focus,
a.text-primary.active {
  color: #205ac5 !important;
}
a.text-secondary:hover,
a.text-secondary:focus,
a.text-secondary.active {
  color: #ff0000 !important;
}
a.text-success:hover,
a.text-success:focus,
a.text-success.active {
  color: #266a73 !important;
}
a.text-info:hover,
a.text-info:focus,
a.text-info.active {
  color: #1283bc !important;
}
a.text-warning:hover,
a.text-warning:focus,
a.text-warning.active {
  color: #facb00 !important;
}
a.text-danger:hover,
a.text-danger:focus,
a.text-danger.active {
  color: #ff5500 !important;
}
a.text-white:hover,
a.text-white:focus,
a.text-white.active {
  color: #c7c7c7 !important;
}
a.text-black:hover,
a.text-black:focus,
a.text-black.active {
  color: #000000 !important;
}
a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption) {
  position: relative;
  background-image: transparent;
  background-size: 10000px 2px;
  background-repeat: no-repeat;
  background-position: 0px 1.2em;
  background-position: -10000px 1.2em;
}
a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover {
  transition: background-position 2s ease-in-out;
  background-image: linear-gradient(currentColor 50%, currentColor 50%);
  background-position: 0px 1.2em;
}
.nav-tabs .nav-link.active {
  color: #6592e6;
}
.nav-tabs .nav-link:not(.active) {
  color: #232323;
}
.alert-success {
  background-color: #70c770;
}
.alert-info {
  background-color: #47b5ed;
}
.alert-warning {
  background-color: #ffe161;
}
.alert-danger {
  background-color: #ff9966;
}
.mbr-gallery-filter li.active .btn {
  background-color: #6592e6;
  border-color: #6592e6;
  color: #ffffff;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
a,
a:hover {
  color: #6592e6;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #a0d8df;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #ffffff;
}
/* Scroll to top button*/
.scrollToTop_wraper {
  display: none;
}
.form-control {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
}
.form-control > .mbr-iconfont {
  font-size: 1.375rem;
}
.form-control:hover,
.form-control:focus {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
  border-color: #6592e6 !important;
}
.form-control:-webkit-input-placeholder {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
}
.form-control:-webkit-input-placeholder > .mbr-iconfont {
  font-size: 1.375rem;
}
blockquote {
  border-color: #6592e6;
}
/* Forms */
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: #6592e6;
  color: #ffffff;
}
.jq-number__spin {
  transition: 0.25s ease;
}
.jq-number__spin:hover {
  border-color: #6592e6;
}
.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: #353535;
  border-bottom-color: #353535;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: #6592e6;
  border-bottom-color: #6592e6;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #ffffff !important;
  background-color: #6592e6 !important;
  box-shadow: none !important;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000000 !important;
  background: #ff6666 !important;
  box-shadow: none !important;
}
.lazy-bg {
  background-image: none !important;
}
.lazy-placeholder:not(section),
.lazy-none {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: auto;
}
iframe.lazy-placeholder,
.lazy-placeholder:after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent no-repeat center;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' stroke='%236592e6' %3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(16 16)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='16' cy='16' r='16'/%3e%3cpath d='M32 16c0-9.94-8.06-16-16-16'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
section.lazy-placeholder:after {
  opacity: 0.5;
}
body {
  overflow-x: hidden;
}
a {
  transition: color 0.6s;
}
.cid-tqnsI4AqmB {
  padding-top: 6rem;
  padding-bottom: 0rem;
  background-color: #9fdbf8;
}
.cid-tqnsI4AqmB .image-wrap img {
  width: 100%;
}
@media (min-width: 992px) {
  .cid-tqnsI4AqmB .image-wrap img {
    display: block;
    margin: auto;
    width: 80%;
  }
}
.cid-tqnsOqrcUK {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #fafafa;
}
.cid-tqnsOqrcUK .mbr-iconfont {
  display: block;
  font-size: 4rem;
  color: #6592e6;
  margin-bottom: 2rem;
}
.cid-tqnsOqrcUK .card-wrapper {
  min-height: 220px;
  transition: all 0.3s;
  border-radius: 4px;
  padding: 1rem;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.cid-tqnsT6R9Ro {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #232323;
}
