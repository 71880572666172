.App {
  text-align: center;
}

.dashboard{

  width: 100%;
  height: 100%;
}

.statistics {
  display: flex;
  justify-content: space-around;  
}

.statistic{
  width:30%;
}


.charts {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.categoryChart{
  width:20vw;
  height: 55vh;
}

.licenseList > .modal-content{
  width:55vw;
  height: 55vh;
}

.licenseList > .modal-content > .modal-body{
  overflow:auto;
}

.licenseList{
  margin:0;
}

.modal-header{
  background-color:rgba(0,0,0,.03);
}

.table-responsive{
  display:table;
}

@media (max-width: 760px) {
  .charts{
    flex-direction: column;
    align-items:center;
    justify-content: center;

  }
  .categoryChart{
    width:100%;
    height: 50%;
    margin-bottom:5%;
  }
  
}

@media (max-width:761px) and (max-height:1500px) {
  .table-responsive{
    display:block;
  }
  .charts{
    flex-direction: column;
    align-items:center;
    justify-content: center;
    
  }

  .licenseList > .modal-content{
    height: 30vh;
    width:75vw;
  }
  
  
}

@media (min-height:500px) and (min-width:761px) and (max-width: 1024px) {

.charts{
  height:50vh;
}

  .categoryChart{
    width:100%;
    height:52vh;
    margin-bottom:5%;
  }

  .licenseList > .modal-content{
    height: 52vh;
  }

  
}

